/** Fonts **
 * The term `font` means an NB Font entity.
 * The terms `google font` & `gfont` mean a Google font.
 */
import clsx from 'clsx';
import { useCallback, useId, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  useBlocker,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';

import {
  BackButtonComponent,
  SaveButtonComponent
} from '../../../components/buttons';
import {
  SystemNameComponent,
  DisplayName,
  EditVdpCodeComponent
} from '../../../components/inputs';
import {
  EntityDependencies,
  TRPCMethodEnum,
  TRPCResourceEnum
} from '../../../api/trpcApi/types';
import { useTRPCRequest } from '../../../hooks';
import { validateSchemaAndNotifyErrors } from '../../../utils';
import {
  CannotDeleteModalComponent,
  ConfirmDeleteModalComponent,
  LoadingModalComponent,
  ModalComponent,
  UnsavedChangesModalComponent
} from '../../../components/modals';
import { Title } from '../../../components/Title';

import { Font, GoogleFont } from '../types';
import { GoogleFonts } from '../components/GoogleFonts';
import FontValidation from '../validations';

import { useFonts } from './hooks';

interface Errors {
  system_name?: string;
  display_name?: string;
  weight?: string;
  googleFont?: string;
  google_family?: string;
  google_version?: string;
  google_variant?: string;
  font_file_path?: string;
  vdpcode?: string;
}

export function FontDetails({ create }: { create?: boolean }) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const id = useId().replace(/:/g, '');
  const { fonts } = useFonts();
  const { handleTRPCRequest } = useTRPCRequest();
  const [isLoading, setIsLoading] = useState(false);
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);
  const [dependencies, setDependencies] = useState<EntityDependencies>({});

  const [googleFont, setGoogleFont] = useState<GoogleFont | null>(null);
  const [existingFilePath, setExistingFilePath] = useState<string>();
  const [variant, setVariant] = useState<string>('');
  const [manualFontFile, setFontFile] = useState<string>('');
  const [system_name, setSystemName] = useState<string>('');
  const [display_name, setDisplayName] = useState<string>('');
  const [weight, setWeight] = useState<string>('');

  const [warnDownload, setWarnDownload] = useState(false);
  const [downloaded, setDownloaded] = useState(false);

  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const unsavedChangesBlocker = useBlocker(unsavedChanges);

  const [vdpCode, setVdpCode] = useState<string | null>(null);

  const setFont = useCallback(
    (newFont: Font) => {
      setWeight(newFont.weight);
      setDisplayName(newFont.display_name);
      setSystemName(newFont.system_name);
      setVariant(newFont.google_variant || '');
      setGoogleFont({
        family: newFont.google_family || '',
        variants: newFont.google_variant ? [newFont.google_variant] : [],
        version: newFont.google_version || '',
        lastModified: '',
        files: {}
      });
      setExistingFilePath(newFont.font_file_path);
      setVdpCode(newFont.vdpcode || '');
    },
    [setWeight, setDisplayName, setSystemName, setVariant]
  );

  const [errors, setErrors] = useState<Errors>({});
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const syncFromRouter = useCallback(() => {
    setIsLoading(true);
    if (location?.state?.font) {
      setFont(location.state.font);
    }
    setIsLoading(false);
  }, [location, setFont]);

  const syncFromApi = useCallback(async () => {
    setIsLoading(true);
    if (params.font_id) {
      const { res: font, error } = await handleTRPCRequest({
        method: TRPCMethodEnum.get,
        resourceType: TRPCResourceEnum.fonts,
        requestBody: {
          font_id: params.font_id
        }
      });

      if (!error) {
        setFont(font);
      }
    }

    setIsLoading(false);
  }, [params.font_id, handleTRPCRequest, setFont, setIsLoading]);

  useEffect(() => {
    if (create) {
      syncFromRouter();
      setUnsavedChanges(true);
    } else {
      syncFromApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleSelectFontVariant = useCallback(
    (gfont: GoogleFont | null, variant: string, fontFile?: string) => {
      setGoogleFont(gfont);
      setVariant(variant);
      setFontFile(fontFile ?? '');
      setUnsavedChanges(true);
      const newWeight = variant.match(/\d+/)?.[0] || '400';
      setWeight(newWeight);

      setErrors(prevErrors => ({ ...prevErrors, googleFont: undefined }));
    },
    []
  );

  const handleSystemNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setErrors(prev => ({ ...prev, system_name: undefined }));
      setSystemName(event.target.value);
      setUnsavedChanges(true);
    },
    [setErrors, setSystemName]
  );

  const handleDisplayNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setErrors(prev => ({ ...prev, display_name: undefined }));
      setDisplayName(event.target.value);
      setUnsavedChanges(true);
    },
    [setErrors, setDisplayName, setUnsavedChanges]
  );

  const handleWeightChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setErrors(prevErrors => ({ ...prevErrors, weight: undefined }));
      setWeight(event.target.value?.replace(/\D/g, ''));
      setUnsavedChanges(true);
    },
    [setErrors, setWeight]
  );

  const handleDuplicateFont = useCallback(() => {
    const font = {
      system_name: system_name + ' - Copy',
      display_name,
      weight,
      google_family: googleFont?.family,
      google_variant: googleFont?.variants[0],
      google_version: googleFont?.version,
      font_file_path: existingFilePath,
      vdpcode: vdpCode
    };
    navigate(`/fonts/new`, { state: { font }, replace: true });
  }, [
    googleFont,
    system_name,
    display_name,
    weight,
    existingFilePath,
    navigate,
    vdpCode
  ]);

  const validate = useCallback(() => {
    let font: Font = {
      system_name,
      display_name,
      weight,
      vdpcode: vdpCode
    };
    if (
      create &&
      fonts.some(({ system_name }) => system_name === font.system_name)
    ) {
      setErrors({ system_name: 'System name in use' });
      toast.error('System name already in use');
      return;
    }
    if (params.font_id) {
      font.font_id = params.font_id;
      font.google_variant = variant;
      font.font_file_path = manualFontFile || googleFont?.files[variant];
    } else {
      font = {
        ...font,
        google_family: googleFont?.family,
        google_version: googleFont?.version,
        google_variant: variant,
        font_file_path: manualFontFile || googleFont?.files[variant]
      };
    }
    if (!font.font_file_path) {
      font.font_file_path = existingFilePath;
    }

    const { errors: validationErrors, validatedSchema } =
      validateSchemaAndNotifyErrors(
        create
          ? FontValidation.createFontSchema
          : FontValidation.updateFontSchema,
        font
      );

    if (validationErrors) {
      setErrors(validationErrors as unknown as Errors);
      return;
    }
    return validatedSchema;
  }, [
    system_name,
    display_name,
    weight,
    create,
    fonts,
    params.font_id,
    variant,
    manualFontFile,
    googleFont?.files,
    googleFont?.family,
    googleFont?.version,
    existingFilePath,
    vdpCode
  ]);

  const handleSave = useCallback(async () => {
    const font = validate();

    if (!font) {
      return;
    }
    setIsSaving(true);
    setUnsavedChanges(false);
    const { res, error } = await handleTRPCRequest({
      method: create ? TRPCMethodEnum.create : TRPCMethodEnum.update,
      resourceType: TRPCResourceEnum.fonts,
      requestBody: font
    });
    if (!error) {
      setErrors({});
      navigate(`/fonts/${res?.font_id}`, { replace: true });
    }
    setIsSaving(false);
    setWarnDownload(false);
    setDownloaded(false);
  }, [create, handleTRPCRequest, navigate, validate]);

  const handleDeleteFont = useCallback(async () => {
    setUnsavedChanges(false);
    setConfirmDelete(false);
    setIsDeleting(true);
    const { res, error } = await handleTRPCRequest({
      method: TRPCMethodEnum.delete,
      resourceType: TRPCResourceEnum.fonts,
      requestBody: { font_id: params.font_id }
    });
    setIsDeleting(false);

    if (!error && res.success) {
      toast.success('Font deleted successfully!');
      navigate('/fonts');
    }

    if (!res?.success) {
      setShowCannotDeleteModal(true);
      setDependencies(res.relatedEntities);
    }
  }, [handleTRPCRequest, navigate, params.font_id]);

  if (isSaving) {
    return <LoadingModalComponent isOpen={isSaving} message="Saving..." />;
  }

  if (isDeleting) {
    return <LoadingModalComponent isOpen={isDeleting} message="Deleting..." />;
  }

  if (isLoading) {
    return <LoadingModalComponent isOpen={isLoading} />;
  }

  return (
    <>
      {create ? <Title title="Create Font" /> : <Title title="Existing Font" />}
      <div className="flex h-full flex-col">
        <SystemNameComponent
          systemName={system_name}
          onDelete={() => setConfirmDelete(true)}
          onDuplicate={handleDuplicateFont}
          create={create}
          onSystemNameChange={handleSystemNameChange}
          errors={{ system_name: !!errors.system_name }}
        />
        <div className={clsx('mb-5 flex gap-2 pb-5')}>
          <DisplayName
            displayName={display_name}
            onDisplayNameChange={handleDisplayNameChange}
            displayNameError={!!errors.display_name}
            className={'w-[75%]'}
          />
          <EditVdpCodeComponent
            className={'flex w-[30vw] flex-col gap-2'}
            vdpCode={vdpCode || ''}
            handleUpdateVdpCode={setVdpCode}
            overrideClassName={true}
            error={!!errors.vdpcode}
          />
        </div>
        <GoogleFonts
          fontError={
            !!(
              errors.google_family ||
              errors.google_version ||
              errors.google_variant ||
              errors.font_file_path
            )
          }
          weightError={!!errors.weight}
          onSelectFontVariant={handleSelectFontVariant}
          weight={weight}
          onChangeWeight={handleWeightChange}
          font={googleFont || undefined}
          create={create}
          variant={variant}
          id={id}
        />

        <div
          className="mt-4 grow text-4xl"
          style={{
            fontFamily: googleFont?.family + id,
            fontWeight: weight,
            fontStyle: variant.includes('italic') ? 'italic' : 'normal'
          }}
        >
          <p>ABCDEFGHIJKLMNOPQRSTUVWXYZ</p>
          <p>abcdefghijklmnopqrstuvwxyz</p>
          <p>1234567890</p>
        </div>

        <div className="mt-10 flex gap-3">
          <BackButtonComponent destination="/fonts" />
          <SaveButtonComponent
            isSaving={isSaving}
            disabled={!googleFont || !weight}
            onSave={
              create && !manualFontFile
                ? () => {
                    if (validate()) {
                      setWarnDownload(true);
                    }
                  }
                : handleSave
            }
          />
        </div>
        <UnsavedChangesModalComponent
          unsavedChanges={unsavedChanges}
          unsavedChangesBlocker={unsavedChangesBlocker}
        />
      </div>
      <ConfirmDeleteModalComponent
        isOpen={confirmDelete}
        entityName={system_name}
        onCancel={() => setConfirmDelete(false)}
        onConfirm={handleDeleteFont}
      />
      <CannotDeleteModalComponent
        entityName={system_name ?? ''}
        dependencies={dependencies}
        isOpen={showCannotDeleteModal}
        onCancel={() => setShowCannotDeleteModal(false)}
      />
      <ModalComponent
        isOpen={warnDownload}
        onRequestClose={() => setWarnDownload(false)}
        contentLabel="Download Font"
      >
        <div className="flex max-w-[30rem] flex-col items-center gap-8 p-6">
          <h1>Make sure to download the font before printing!</h1>
          <div className="flex min-w-[10rem] justify-stretch gap-4">
            {!downloaded && (
              <a
                className="bg-navy flex flex-1 items-center justify-center rounded-full px-4 text-white"
                href={`https://fonts.google.com/specimen/${googleFont?.family}`}
                target="_blank"
                onClick={() => setDownloaded(true)}
              >
                Link to Download
              </a>
            )}
            <SaveButtonComponent
              text={downloaded ? 'Save' : 'Save Without Downloading'}
              secondary={!downloaded}
              isSaving={isSaving}
              onSave={handleSave}
            />
          </div>
        </div>
      </ModalComponent>
    </>
  );
}
